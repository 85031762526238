import isCallable from '../common/isCallable';
import removableListener from '../common/removableListener';

const maxTimeout = 1000;
const intervalDelay = 100;
const noChangeCountToEnd = 3;

/**
 * Modification of https://github.com/gajus/orientationchangeend
 *
 * @param cb
 */

const onOrientationChange = function (cb) {
  isCallable({cb,
    throwError: true});

  let intervalId;
  let timeoutId;
  let lastInnerWidth;
  let lastInnerHeight;
  let noChangeCount;

  const done = function (event) {
    clearInterval(intervalId);
    clearTimeout(timeoutId);

    if (!event) {
      return;
    }
    intervalId = null;
    timeoutId = null;

    cb(event);
  };

  const eventHandler = function (event) {
    done();
    intervalId = setInterval(() => {
      if (window.innerWidth === lastInnerWidth && window.innerHeight === lastInnerHeight) {
        noChangeCount++;
        if (noChangeCount === noChangeCountToEnd) {
          done(event);
        }
      } else {
        lastInnerWidth = window.innerWidth;
        lastInnerHeight = window.innerHeight;
        noChangeCount = 0;
      }
    }, intervalDelay);

    timeoutId = setTimeout(() => {
      done(event);
    }, maxTimeout);
  };

  const resizeEvent = ['resize', eventHandler];
  const orientationChangeEvent = ['orientationchange', eventHandler];

  window.addEventListener(...resizeEvent);
  window.addEventListener(...orientationChangeEvent);

  return removableListener(() => {
    window.removeEventListener(...resizeEvent);
    window.removeEventListener(...orientationChangeEvent);
  });
};

export default onOrientationChange;
